<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${tax.name}` }} - {{ $t("COMMON.LOGS") }}
      </span>
    </div>

    <log-list-table :filterSubject="tax" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "tax-view-logs",

  components: { LogListTable },

  props: ["tax"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
