<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ tax.name }}</span>
    </div>

    <div>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("TAXES.ACTIVE") }}</dt>
        <dd class="col-sm-8">
          <icon-check :checked="!!tax.active" />
          {{ $t("TAXES.ACTIVE") }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("TAXES.LABEL") }}</dt>
        <dd class="col-sm-8">
          {{ tax.label }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("TAXES.LABEL") }}</dt>
        <dd class="col-sm-8">
          {{ tax.label }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("TAXES.TAX_NUMBER") }}</dt>
        <dd class="col-sm-8">
          {{ tax.tax_number }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("TAXES.TAX_TYPE") }}</dt>
        <dd class="col-sm-8">
          {{ $t(`TAXES.TAX_TYPE_${tax.tax_type}`) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("TAXES.TAX_CALCULATION_TYPE") }}</dt>
        <dd class="col-sm-8">
          {{ $t(`TAXES.TAX_CALCULATION_TYPE_${tax.calculation_type}`) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("TAXES.TAX_CALCULATION_BASE") }}</dt>
        <dd class="col-sm-8">
          {{ $t(`TAXES.TAX_CALCULATION_BASE_${tax.calculation_base}`) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("TAXES.VALUE") }}</dt>
        <dd class="col-sm-8">
          {{ tax.value }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8">
          <div v-html="tax.excerpt"></div>
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="tax.organization" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="tax.created_at">
          {{ $timeZoneDateFormat(tax.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="tax.updated_at">
          {{ $timeZoneDateFormat(tax.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import IconCheck from "@/components/IconCheck.vue";
export default {
  name: "tax-view-global",

  components: { IconCheck },

  props: ["tax"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    tax(tax) {},
  },
};
</script>
